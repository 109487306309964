<template>
  <el-card class="x-card-title" shadow="hover" header="">
    <div class="row no-gutters align-items-start">
      <div class="col-auto">
        <el-upload
          class="avatar-uploader"
          :action="`${MixinUploadApi}`"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img class="avatar" :src="personal && personal.face ? personal.face : '/img/default.jpg'" alt=""/>
        </el-upload>
      </div>

      <el-form class="col pl-4" label-position="right">
        <el-form-item label="登录账号">
          {{ personal.uname }}
        </el-form-item>
        <el-form-item label="ID" label-width="68px">
          {{ personal.member_id }}
        </el-form-item>
        <el-form-item label="注册时间">
          {{ personal.create_time }}
        </el-form-item>
        <el-form-item
          label="登录密码"
          error="true"
        >
          <div slot="error" style="font-size: 80%; color: #888; padding-left: 68px; line-height: 1">
            安全性高的密码可以使账号更安全。建议您定期更换密码,设置一个包含字母,符号或数字中至少两项且长度超过6位的密码。
          </div>
          <el-button type="primary" size="mini" @click="revs">重置</el-button>
        </el-form-item>
<!--        <el-form-item label="手机绑定">-->
<!--          {{ oldTel }}-->
<!--          <el-button type="text" size="mini" @click="chang">更换手机号</el-button>-->
<!--        </el-form-item>-->
      </el-form>
    </div>

    <x-dialog :proxy="modifyPwdDialog">
      <el-form
        :model="dataForm"
        ref="dataForm"
        :rules="rules"
        class="demo-ruleForm"
        label-position="right"
        label-width="180px"
        inline
      >
        <el-form-item label="原密码" prop="oldPwd" class="d-block">
          <el-input v-model="dataForm.oldPwd" type="password" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd" class="d-block">
          <el-input v-model="dataForm.newPwd" type="password" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="endPwd" class="d-block">
          <el-input v-model="dataForm.endPwd" type="password" style="width: 300px"></el-input>
        </el-form-item>
<!--        <el-form-item label="图片验证码" prop="captcha" class="d-block">-->
<!--          <el-input v-model="dataForm.captcha" style="width: 300px">-->
<!--            <img-->
<!--              :src="validcodeImg"-->
<!--              style="height: 38px; margin: 0 -20px"-->
<!--              slot="append"-->
<!--              @click="changeValidcodeA"-->
<!--              alt=""/>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
      </el-form>
    </x-dialog>

    <x-dialog :proxy="modifyMobileDialog">
      <el-form
        :model="dataTel"
        ref="dataTel"
        :rules="rulesA"
        label-width="200px"
        class="demo-ruleForm"
        label-position="right"
        inline
      >
        <el-form-item label="当前手机号：">
          <span>{{ oldTel }}</span>
        </el-form-item>
        <el-form-item label="图片验证码：" prop="captcha">
          <el-input v-model.number="dataTel.captcha" maxlength="4">
            <img
              :src="validcodeImg"
              style="height: 38px; margin: 0 -20px"
              slot="append"
              @click="changeValidcode"
              alt=""/>
          </el-input>
        </el-form-item>
        <el-form-item label="新手机号：" prop="mobile">
          <el-input v-model.number="dataTel.mobile">
            <el-button slot="append" :disabled="ty === 'info'" @click="getCode()">
              获取验证码
              <span v-show="ty === 'info'">{{ time }}秒</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="sms_code">
          <el-input v-model="dataTel.sms_code"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </el-card>
</template>

<script>
import {RegExp} from '@/../ui-utils';
import * as API from '@/api/shopSetting';
import * as API_Common from '@/api/common';
import Storage from '@/utils/storage';
import crypto from 'crypto';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {$auth} from "@/pages/auth/services/auth.service";

export default {
  name: 'safeSetting',
  components: {XDialog},
  data() {
    const validatePwd = (rule, value, callback) => {
      var rex = /^[@A-Za-z0-9!#$%^&*.~,]{6,20}$/;
      if (!value) {
        callback(new Error('请输入密码'));
      } else if (!rex.test(value)) {
        callback(new Error('请输入正确的密码'));
      } else {
        callback();
      }
    };
    const validateNewPwd = (rule, value, callback) => {
      if (value !== this.dataForm.newPwd) {
        callback(new Error('密码不一致'));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'));
      } else if (!RegExp.mobile.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      modifyPwdDialog: $xDialog.create({
        title: '修改密码',
        beforeCancel: () => this.cancel('dataForm'),
        beforeConfirm: () => this.save('dataForm'),
      }),
      modifyMobileDialog: $xDialog.create({
        title: '更换手机号',
        beforeCancel: () => this.cancel('dataTel'),
        beforeConfirm: () => this.save('dataTel'),
      }),
      personal: {},
      validcodeImg: '',
      uuid: '',
      url: '/img/default.jpg',
      dialogTableVisible: false,
      dialogTableVisible1: false,
      oldTel: '130****5253',
      dataForm: {
        oldPwd: '',
        newPwd: '',
        endPwd: '',
        captcha: ''
      },
      ty: 'primary',
      dataTel: {
        mobile: '',
        sms_code: '',
        captcha: ''
      },
      time: 30,
      rules: {
        oldPwd: [{
          required: true,
          message: '请输入原密码',
          trigger: 'blur'
        }],
        newPwd: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          },
          {
            validator: validatePwd,
            trigger: 'blur'
          }
        ],

        endPwd: [
          {
            required: true,
            message: '请确认密码',
            trigger: 'blur'
          },
          {
            validator: validateNewPwd,
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            required: true,
            message: '请输入图片验证码',
            trigger: 'blur'
          }
        ]
      },
      rulesA: {
        sms_code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入新手机号',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            required: true,
            message: '请输入图片验证码',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    const uuid = Storage.getItem('seller_uuid');

    if (uuid) {
      this.uuid = uuid;
    } else {
      const _uuid = uuidv1();
      this.uuid = _uuid;
      Storage.setItem('seller_uuid', _uuid, {expires: 30});
    }
  },
  created() {
    this.getUserIn();
  },
  methods: {
    beforeAvatarUpload() {
    },
    handleAvatarSuccess(res, file) {
      this.personal.face = res.url;
      let params = {
        face: this.personal.face
      };

      API.saveUserImg(params).then(response => {
        this.$message.success('修改成功');
      });
    },
    changeValidcodeA() {
      this.validcodeImg = API_Common.getValidateCodeUrl(
        'MODIFY_PASSWORD',
        this.uuid
      );
    },
    changeValidcode() {
      this.validcodeImg = API_Common.getValidateCodeUrl(
        'BIND_MOBILE',
        this.uuid
      );
    },
    //获取验证码
    getCode() {
      if (this.dataTel.captcha === '') {
        this.$message.error('请输入图片验证码！');
        return;
      } else if (!this.dataTel.mobile) {
        this.$message.error('请输入新手机号码！');
        return;
      }
      if (this.ty === 'info' || !RegExp.mobile.test(this.dataTel.mobile)) {
        this.$message.error('新手机号格式有误！');
        return;
      }

      let para = {
        uuid: this.uuid,
        captcha: this.dataTel.captcha
      };
      // console.log(this.validcodeImg, 'validcodeImg');
      let self = this;
      API.sendMobile(this.dataTel.mobile, para)
        .then(response => {
          // console.log(response);
          this.ty = 'info';
          let st = setInterval(function () {
            self.time = self.time - 1;
            if (self.time === 0) {
              self.ty = 'primary';
              clearInterval(st);
              self.time = 30;
            }
          }, 1000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-';
      var D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      var h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      var m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':';
      var s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getUserIn() {
      API.getUserInfo().then(res => {
        res.create_time = this.timestampToTime(res.create_time);
        this.personal = res;
        this.oldTel =
          res.mobile.substring(0, 3) + '****' + res.mobile.substring(7);
      });
    },
    //修改密码/修改绑定手机号
    save(formName) {
      return new Promise(resolve => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            if (formName === 'dataForm') {
              let params = {
                uuid: $auth.currentRole.uuid,
                password: this.dataForm.newPwd,
                old_password: this.dataForm.oldPwd,
              };
              let md5 = crypto.createHash('md5');
              md5.update(params.password);
              const md5pwd = md5.digest('hex');
              params.password = md5pwd;

              let md5o = crypto.createHash('md5');
              md5o.update(params.old_password);
              const md5opwd = md5o.digest('hex');
              params.old_password = md5opwd;
              API.changePwd(params)
                .then(response => {
                  this.$message.success('修改密码成功');
                  this.getUserIn();
                  resolve(true);
                })
                .catch(() => {
                  this.loading = false;
                });
              // console.log(params, 'md5pwdmd5pwdmd5pwdmd5pwd');
            } else if (formName === 'dataTel') {
              // console.log(this.dataTel, 'this.dataTel');
              API.changeMobile(this.dataTel.mobile, this.dataTel)
                .then(response => {
                  this.$message.success('更换手机成功');
                  this.getUserIn();
                  resolve(true);
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          } else {
            resolve(false);
          }
        });
      })
    },
    //取消
    cancel(formName) {
      if (this.dialogTableVisible) {
        this.dialogTableVisible = false;
      }
      if (this.dialogTableVisible1) {
        this.dialogTableVisible1 = false;
      }

      this.$refs[formName].resetFields();
    },
    //重置密码
    revs: function () {
      this.validcodeImg = API_Common.getValidateCodeUrl(
        'MODIFY_PASSWORD',
        this.uuid
      );
      this.modifyPwdDialog.display();
    },
    //更换手机号
    chang: function () {
      this.validcodeImg = API_Common.getValidateCodeUrl(
        'BIND_MOBILE',
        this.uuid
      );
      this.modifyMobileDialog.display();
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload--text {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}
</style>
